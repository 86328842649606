import { Svg } from 'packages/uikit'

const Icon = (props) => {
  return (
    <Svg width="14" height="15" viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.4974 2.4987C1.4974 1.57943 2.24479 0.832031 3.16406 0.832031H7.33073C8.25 0.832031 8.9974 1.57943 8.9974 2.4987V7.4987H9.20573C10.4714 7.4987 11.4974 8.52474 11.4974 9.79037V10.6237C11.4974 10.9701 11.776 11.2487 12.1224 11.2487C12.4688 11.2487 12.7474 10.9701 12.7474 10.6237V6.61328C12.0286 6.42839 11.4974 5.77474 11.4974 4.9987V3.33203L10.6641 2.4987C10.4349 2.26953 10.4349 1.89453 10.6641 1.66536C10.8932 1.4362 11.2682 1.4362 11.4974 1.66536L13.5104 3.67839C13.8229 3.99089 13.9974 4.41536 13.9974 4.85807V5.20703V5.83203V6.66536V10.6237C13.9974 11.6602 13.1589 12.4987 12.1224 12.4987C11.0859 12.4987 10.2474 11.6602 10.2474 10.6237V9.79037C10.2474 9.21484 9.78125 8.7487 9.20573 8.7487H8.9974V12.4987C9.45833 12.4987 9.83073 12.8711 9.83073 13.332C9.83073 13.793 9.45833 14.1654 8.9974 14.1654H1.4974C1.03646 14.1654 0.664062 13.793 0.664062 13.332C0.664062 12.8711 1.03646 12.4987 1.4974 12.4987V2.4987ZM3.16406 2.91536V5.41536C3.16406 5.64453 3.35156 5.83203 3.58073 5.83203H6.91406C7.14323 5.83203 7.33073 5.64453 7.33073 5.41536V2.91536C7.33073 2.6862 7.14323 2.4987 6.91406 2.4987H3.58073C3.35156 2.4987 3.16406 2.6862 3.16406 2.91536Z"
        fill="black"
      />
    </Svg>
  )
}

export default Icon
