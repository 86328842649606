import { Svg } from 'packages/uikit'

const Icon = (props) => {
  return (
    <Svg viewBox="0 0 18 18" {...props}>
      <path d="M15.25 0.25C16.6289 0.25 17.75 1.37109 17.75 2.75V15.25C17.75 16.6289 16.6289 17.75 15.25 17.75H2.75C1.37109 17.75 0.25 16.6289 0.25 15.25V2.75C0.25 1.37109 1.37109 0.25 2.75 0.25H15.25ZM12.75 11.2422V5.875C12.75 5.53125 12.4688 5.25 12.125 5.25H6.75781C6.26953 5.25 5.875 5.64453 5.875 6.13281C5.875 6.36719 5.96875 6.59375 6.13281 6.75781L7.4375 8.0625L4.84766 10.6523C4.70312 10.7969 4.625 10.9883 4.625 11.1875C4.625 11.3867 4.70312 11.5781 4.84766 11.7227L6.28125 13.1562C6.42187 13.2969 6.61328 13.3789 6.81641 13.3789C7.01953 13.3789 7.20703 13.3008 7.35156 13.1562L9.9375 10.5625L11.2422 11.8672C11.4062 12.0312 11.6328 12.125 11.8672 12.125C12.3555 12.125 12.75 11.7305 12.75 11.2422Z" />
    </Svg>
  )
}

export default Icon
