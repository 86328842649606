import { Svg } from 'packages/uikit'

const Icon = (props) => {
  return (
    <Svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.1737 12.8257L6.9987 14.0007L10.332 17.334L13.6654 14.0007L12.4904 12.8257L11.1654 14.1423L11.1654 1.50065H9.4987L9.4987 14.1423L8.1737 12.8257Z"
        fill="white"
      />
      <path
        d="M5.8237 5.17565L6.9987 4.00065L3.66536 0.667316L0.332031 4.00065L1.50703 5.17565L2.83203 3.85898L2.83203 16.5007H4.4987L4.4987 3.85898L5.8237 5.17565Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
